@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700');

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    color: #FFFFFF;
    font-size: 14px;
    overflow-x: hidden;
}

/* top players tab */
.top-tab-app.react-tabs__tab--selected {
    background-color: #1684e4 !important;
    border-radius: 5px;
}

.top-tab-admin.react-tabs__tab--selected {
    background-color: white !important;
    border-radius: 5px;
}

/* loader */
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #f07873;
}

/* button */

.btn-custom {
    display: inline-block;
    background: white;
    color: #444;
    width: 100%;
    border-radius: 5px;
    border: thin solid #888;
    box-shadow: 1px 1px 1px grey;
    white-space: nowrap;
    text-align: center;
}

.bm_featureicon i {
    color: white !important;
}

.btn-close {
    color: white;
}

.btn-primary {
    border: none !important;
}

.loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    overflow: hidden;
    background: #fff;
}

.loader {
    font-size: 10px;
    border-top: .8em solid rgba(218, 219, 223, 1);
    border-right: .8em solid rgba(218, 219, 223, 1);
    border-bottom: .8em solid rgba(218, 219, 223, 1);
    border-left: .8em solid #f07873;
    left: 50%;
    margin-left: -4em;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -4.05em;
}

.bm-btn-white {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px;
    border: 2px solid #fff;
    display: inline-block;
    transition: all .4s ease 0s;
    border-radius: 5px;
    width: 100%;
}

.bm-btn-white:hover {
    color: #fff;
    transition: all 0.4s ease 0s;
    letter-spacing: 1px;
    text-decoration: none;
}

.btn-lightpink {
    background: #f07873 !important;
    color: #fff !important;
}

.btn-gray {
    background: #6c757d !important;
    color: #fff !important;
    border-color: #6c757d !important;
}

.btn-gray:hover {
    background: #6c757d !important;
    color: #fff !important;
    outline: none !important;
}

.btn-lightpink:hover {
    background: #f07873 !important;
    outline: none !important;
}


.btn-green {
    background: #59daa4;
    color: #fff !important;
}

.btn-lightgreen {
    background: #91e2c0;
    color: #fff !important;
}

.btn-lightgray {
    background-color: gray;
}

.btn-primary {
    border: none !important;
}

#tournaments .btn:hover {
    opacity: 0.7;
}

a.btn-lightpink {
    margin-right: 0.5rem;
}

#download a.btn {
    transform: scale(1);
    transition: .3s ease-out all;
}

#download a.btn:hover {
    transform: scale(1.1);
    opacity: 0.9;
}

.btn-submit {
    transform: scale(1);
    transition: all 0.4s;
}

.btn-submit:hover {
    transform: scale(1.05);
    outline: none;
}

.btn-render {
    border-radius: 6px;
    background-color: rgb(105, 51, 211);
    color: rgb(255, 255, 255);
    border: none;
    font-size: 14px;
    padding: 11px 16px;
}

.btn {
    outline: none !important;
}

.btn:focus {
    outline: none !important;
}

main .btn-lightpink {
    background: #f07873 !important;
    color: #fff !important;
}

main .btn-lightgreen {
    background: #91e2c0 !important;
    color: #fff !important;
}

main .btn-green {
    background: #59daa4 !important;
    color: #fff !important;
}

main .btn-white {
    background: #fff;
    color: #000;
}

main .tournaments .btn:hover {
    opacity: 0.7;
}

main .bm_text_lightgreen {
    color: #59daa4 !important;
}

main .bm-mdl-footer a.btn {
    border-radius: 0 0 10px 10px;
    padding: 5px;
}

main .wallet a.btn {
    width: 100px;
}

main .btn.disabled, main .btn:disabled {
    opacity: .65;
    cursor: not-allowed !important;
}

.btn-lightgreen {
    background: #91e2c0;
    color: #fff !important;
}

.bm-mdl-footer a.btn {
    border-radius: 0 0 10px 10px;
    padding: 5px;
}

HEAD .wallet a.btn {
    width: 100px;
}

HEAD .wallet a.btn {
    width: 100px;
}

.btn-green {
    background: #59daa4;
    color: #fff !important;
}

.btn-lightpink {
    background: #f07873;
    color: #fff !important;
}

.btn-green {
    background: #59daa4;
    color: #fff !important;
}

.btn-block {
    display: block;
}

#menu-toggle .btn.focus, .btn:focus {
    outline: 0;
    box-shadow: unset !important;
}

a.btn-sm.btn-outline-secondary {
    color: #f07873;
    border-color: #f07873;
}

a.btn-sm.btn-outline-secondary:hover {
    color: #fff;
    background-color: #f07873;
    border-color: #f07873;
}

.btn-lightpink {
    background: #f07873;
    color: #fff !important;
}

.btn-lightgreen {
    background: #91e2c0;
    color: #fff !important;
}

/* pointer */
.pointer {
    cursor: pointer;
}

/* main */
.form-control {
    appearance: auto !important;
    -webkit-appearance: auto !important;
}

/*count-down*/
.split {
    color: black !important;
}

.b-bottom {
    border-bottom: 2px solid lightgray !important;
}

span.icon {
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 30px;
}

span.google-icon {
    background: url(https://demowebapp.thebattlemania.com/application/views/themes/front/assest/img/g-normal.png) transparent 5px 50% no-repeat !important;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
}

.scroll {
    overflow-y: hidden;
}


.react-multi-carousel-list ul {

    transition: unset !important;
    overflow: unset !important;
}

.mp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
    position: fixed;
}

.mp-contanier {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.mp-image-holder, .mp-content {
    max-width: 100%;
}

.mp-auto-cursor .mp-content {
    cursor: auto;
}

.mp-gallery .mp-image-holder .mp-figure {
    cursor: pointer;
}

.mp-figure {
    line-height: 0;
}

img .mp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

img {
    vertical-align: middle;
    border-style: none;
}

.mp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mp-title {
    text-align: left;
    line-height: 18px;
    color: #f3f3f3;
    word-wrap: break-word;
    padding-right: 36px;
}

.mp-counter {
    position: absolute;
    bottom: 14%;
    right: 43%;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

.mp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

button.mp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

.mp-s-ready .mp-preloader {
    display: none !important;
}

.mp-arrow:hover, .mp-arrow:focus {
    opacity: 1;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

.dnone {
    display: none;
}

.dblock {
    display: block;
    cursor: zoom-out;
}

.slideInRight {
    animation-name: slideInRight;
    animation-duration: 2s;
    animation-fill-mode: both;
    position: relative;
}

.bm-close-button {
    position: relative;
    top: 100px;
    left: 200px;
}

@keyframes slideInRight {
    0% {
        left: 60%;
        transform: translate(0.4);
    }

    100% {
        left: 10%;
        transform: translate(0.9);

    }

}

.error {
    color: red !important;
}

.masthead {
    min-height: 500px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.bm-px-110 {
    padding: 110px 0px !important;
}

.nav-bg {
    background: #363636;
}

.bm-light-bg {
    background-color: #f3f3f3;
}

.typed-cursor {
    opacity: 1;
    animation: blink .7s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.bm_button1 {
    color: #f07873;
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px;
    border: 2px solid #f07873;
    display: inline-block;
    transition: all 0.4s ease 0s;
    border-radius: 5px;
}

.bm_button1:hover {
    color: #59daa4;
    transition: all 0.4s ease 0s;
    letter-spacing: 1px;
    border: 2px solid #59daa4;
    text-decoration: none;
}

.bm_text_lightgreen {
    color: #59daa4 !important;
}

.bm_text_lightpink {
    color: #f07873 !important;
}

.bm_link_lightpink {
    text-decoration: none;
    color: #f07873 !important;
}

.bm_link_lightpink:hover {
    text-decoration: underline !important;
    color: #59daa4 !important;
}

.bm-bg-lightgreen {
    background-color: #59daa4 !important;
}

.banner_subtext {
    font-size: 16px;
}

.bm_section_title {
    font-size: 2.5rem;
}

.bm_section_subtitle {
    font-size: 16px;
}

.bm_mb30 {
    margin-bottom: 30px;
}

/* Tabs*/
.bm-section-padding {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    text-transform: uppercase;
}

.bm-dark-bg {
    background: #363636;
    color: #eee;
}

#tabs h6.section-title, #howtoplay h6.section-title {
    color: #eee;
}

.nav-tabs {
    border: none;
}

#tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #f07873 !important;
    background-color: transparent;
    border-color: transparent transparent #f07873 !important;
    border-bottom: 4px solid !important;
    font-size: 20px;
    font-weight: bold;
}

main .nav-tabs .nav-link.active {
    color: #000 !important;
    background-color: #1684e4;
    border-color: none !important;
    border: none !important;
    font-size: 14px;
    font-weight: 100;
}

#tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #eee;
    font-size: 20px;
    text-transform: uppercase;
    border-bottom: 2px solid #eee;
}

.carousel-nav-icon {
    height: 48px;
    width: 48px;
}

.carousel-item .col, .col-sm, .col-md {
    margin: 8px;
    height: 300px;
    background-size: cover;
    background-position: center center;
}

.center-tab .bm_featurecard.first-card, .left-tab .bm_featurecard.first-card, .right-tab .bm_featurecard.first-card {
    margin-top: 4.25rem;
    margin-bottom: 0.75rem;
}

.center-tab .bm_featuretext, .left-tab .bm_featuretext, .right-tab .bm_featuretext {
    display: inline-block;
    width: 73%;
}

.center-tab .left-pane .bm_featuretext {
    text-align: right;
}

#tabs .card {
    border: none;
    background: transparent;
}

#tabs .card .card-body {
    padding: 1rem 0 1.5rem 0;
}

.center-tab .left-pane .bm_featureicon {
    float: none;
    margin-right: 0;
    margin-left: 1rem;
}

#nav-tabContent .bm_featureicon {
    width: 4.5rem;
    height: 4.5rem;
    display: inline-block;
    border-radius: 50%;
    background-color: #f07873;
    text-align: center;
    vertical-align: top;
}

.header-dd-menu {
    top: 48px;
    border-radius: 0 0 .25rem .25rem;
    border: none;
}

.header-dd-menu .dropdown-item {
    text-align: left;
    padding: 2px 10px !important;
}

.header-dd-menu .dropdown-item:focus, .header-dd-menu .dropdown-item:hover,
.header-dd-menu .dropdown-item.active, .header-dd-menu .dropdown-item:active {
    color: #f8f9fa !important;
    text-decoration: none !important;
    background-color: #f07873 !important;
}

.center-tab .right-pane .bm_featureicon, .left-tab .right-pane .bm_featureicon, .right-tab .right-pane .bm_featureicon {
    margin-right: 1rem;
}

#nav-tabContent .bm_featureicon .fa {
    font-size: 2.5rem;
    line-height: 4.95rem;
}

.right-tab .bm-knowtext {
    margin-top: 1.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

.right-tab .bm-knowtext h3 {
    margin-bottom: 0.75rem;
}

.right-tab .bm-iconsarea div.card:nth-child(2n+2), .left-tab .bm-iconsarea div.card:nth-child(2n+2) {
    margin-right: 0rem;
}

.bm-bg-lightpink {
    background: #f07873;
}

#howtoplay .img-wrapper img {
    filter: drop-shadow(5.5px 2.5px 10.5px rgba(255, 255, 255, 0.4));
    margin: 2rem auto 2rem auto;
    transform: scale(1);
    transition: all 0.4s;
}

#howtoplay .img-wrapper img:hover {
    transform: scale(1.1);
}

/* Thin out the marketing headings */
.featurette-heading {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.05rem;
}

/* tournaments */
#tournaments .card-body {
    padding: 22px 15px !important;
}

#players-card .card-body {
    padding: 167px 0px !important;
}

.bm-card-info-item {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

.bm-card-table-item {
    display: block;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
}

.bm-card-table-item-default {
    font-size: 12px;
    font-weight: 600;
    color: #bfbfbf;
}

#tournaments .card-img-top {
    transform: scale(1);
    transition: .3s ease-out all;
}

#tournaments .card-img-top:hover {
    transform: scale(1.1);
}

.hide-over {
    overflow: hidden;
}

.bm-rate-card {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 18px;
    padding-right: 18px;
    transition: .3s ease-out all;
    opacity: 1;
}

.bm-rate-user, .bm-rate-place {
    font-size: 12px;
    font-weight: 500;
    color: #c1c1c1;
}

.bm-rate-wins {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #9b9b9b;
    text-align: right !important;
}

.bm-rate-position {
    display: inline-block;
    vertical-align: middle;
}

.br-5 {
    border-radius: 5px;
}

.bm-rate-position {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    font-weight: 500;
    color: #c1c1c1;
}

.bm-rate-name {
    font-size: 13px;
    font-weight: 500;
    color: #8a8a8a;
}

.rate-col .card-table.table thead th, .rate-col .card-table.table tbody td {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    vertical-align: middle;
}

.card-table.table thead th {
    border: none;
}

.tour-card .card-table td {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    padding: 2.5px !important;
    vertical-align: middle;
}

.card-img-top {
    height: 150px !important;
}

/* user */
.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 1rem;
}

.App-link {
    color: #000;
}

.dropin-parent {
    max-width: 420px;
    margin: auto;
    margin-top: 20px;
}

.order-token {
    font-weight: 600;
}

.inputText {
    width: 200px;
}

input:focus {
    outline-color: rgb(105, 51, 211);
}

.style-dropin {
    outline: 0;
    border-width: 0 0 1px;
    border-radius: 0px;
}

.style-dropin:focus {
    border-color: rgb(105, 51, 211);
}

.style-container {
    margin-bottom: 8px;
}

/* cashfree end */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    color: #FFFFFF;
    font-size: 14px;
}

.pr-1, .px-1 {
    padding-right: .25rem !important;
}

.pl-1, .px-1 {
    padding-left: .25rem !important;
}

/*All_game*/
.border-dashed {
    width: fit-content;
    border: 2px dashed black;
}

.tournaments .card-img-top :hover {
    transform: scale(1);
    transition: .3s ease-out all;
}

.no-underline {
    text-decoration: none;
}

.scroll-game {
    overflow-y: scroll !important;
    height: 90%;
}

.all-games-nav {
    justify-content: space-around !important;
}

.bm-tot-match {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 5px;
}

/* 
.modal-backdrop.show {
    opacity: 0.1 !important;
} */

.modal-backdrop.show {
    opacity: 0.5 !important;
}

.games {
    border-radius: 5px;

}

.game-name {
    border-radius: 0 0 5px 5px;
    box-shadow: 1px 1px 2px 1px #00000026;
}

.hideicon {
    color: lightgray;
}


.table>:not(:first-child) {
    border-top: 2px solid #dee2e6;
}

main p {
    margin: 0;
}

main .p-0 {
    padding: 0;
}

main .p-10 {
    padding: 10px;
}

main .pb-6 {
    padding-bottom: 5rem !important;
}

main .text-black {
    color: #000;
}

main .bg-black {
    background-color: #000;
}

main a:hover {
    color: #fff;
    text-decoration: none;
}

main.bm-full-width {
    display: block;
    background-image: url(../images/main.jpg);
    background-position: top right;
    background-size: cover;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    position: relative;
}

main.bm-full-height {
    height: 100vh;
}

main .bm-modal {
    background-color: #f7f6fb;
    border-radius: 15px;
    max-width: 500px;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, .5);
    height: calc(100vh - 40px);
    margin-top: 20px;
    overflow: hidden;
    margin-left: 20px;
    margin-right: auto;
    position: relative !important;
}

main .bm-mdl-header {
    background-color: #2398ff;
    color: #fff;
    padding: 15px 20px;
}

main .f-14 {
    font-size: 14px !important;
}

main .f-18 {
    font-size: 18px !important;
}

main .f-20 {
    font-size: 20px !important;
}

main .f-24 {
    font-size: 24px !important;
}

main .f-12 {
    font-size: 12px !important;
}

main .bm-mdl-header .badge {
    padding: .15em .3em;
    font-weight: 400;
}

main .bm-mdl-footer {
    background-color: #2398ff;
    color: #fff;
    position: fixed;
    bottom: 20px;
    width: 500px;
    border-radius: 0 0 15px 15px;
}

main .bm-mdl-footer nav {
    width: 100%;
    display: block;
    padding: 0;
}

main .navbar li {
    display: block;
    width: 100%;
    text-align: center;
}

main .navbar li a {
    color: #fff !important;
    padding-top: .8rem;
}

main .navbar li a:hover, main .navbar li.active a {
    color: #000 !important;
}

main .bm-mdl-center {
    padding-bottom: 126px;
    display: block;
    height: calc(100vh - 40px);
}

.f-30 {
    font-size: 30px !important;
}

.mCSB_inside>.mCSB_container {
    margin-right: 0;
}

.bm_text_lightgreen {
    color: #59daa4 !important;
}

.profile-form label, .reset-pwd label {
    margin: 0;
}

.error, .required {
    color: #ff0000;
}

.profile-form select, .profile-form input, .reset-pwd input, .profile-form input {
    border: none;
    background: none;
    padding: 0 !important;
    height: calc(1.5em + .5rem + 2px);
    font-size: 14px;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.profile-form select, .profile-form input, .reset-pwd input, .profile-form input {
    border: none;
    background: none;
    padding: 0 !important;
    height: calc(1.5em + .5rem + 2px);
    font-size: 14px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #2c2c2c !important;
}

.mCSB_scrollTools {
    width: 9px;
}

main .bm-mdl-center .content-section {
    display: block;
    height: 100%;
    /* overflow-y: scroll;
    -webkit-overflow-scrolling: touch; */
}

main .bm-content-listing {
    margin: 10px;
}

main .bm-single-game {
    min-height: 160px;
    position: relative;
    /*margin-bottom:20px;*/
    display: block;
    border-radius: 5px 5px 0 0;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
}

.b-shadow:hover {
    box-shadow: none;
}

main .box-shadow {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
}

/*.bm-single-game{
    min-height:160px;
    position:relative;
    margin-bottom:20px;
    display:block;
    border-radius:5px;
    background:#fff;
    box-shadow: 0 3px 6px rgba(0,0,0,.08);
}*/

.active {
    display: block;
}

.hide {
    display: none;
}

main .game {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

main .game1 {
    background-image: url('../images/card1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

main .game2 {
    background-image: url('../images/card2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

main .game3 {
    background-image: url('../images/card3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

main .bm-single-href, main .bm-single-href:hover {
    color: #000;
}

main .bm-game-name {
    /*position:absolute;*/
    bottom: 0;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    color: #000;
    padding: 8px;
    /*background:rgba(0,0,0,0.7);*/
    border-radius: 0 0 5px 5px;
}

main .bm-tot-match {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 5px;
}

/*.bm-game-name{
    position:absolute;
    bottom:0;
    width:100%;
    text-align:center;
    color:#fff;
    padding:8px;
    background:rgba(0,0,0,0.7);
    border-radius: 0 0 5px 5px;
}*/
main .refer-bg {
    padding: 20px 10px;
}

main .bm-user-info {
    background: #2398ff;
    padding: 20px 20px 80px;
}

main .profile-img {
    background: #fff;
    border-radius: 3px;
    /* padding: 10px; */
    width: 80px;
}

main .bm-game-info {
    background: #59daa4;
    width: 87%;
    margin: auto;
    margin-top: -50px;
    padding: 10px;
    border-radius: 5px;
}

main .bm-game-info .col-4 {
    padding: 5px 20px;
}

main .bm-game-info .col-4:not(:last-child) {
    border-right: 1px solid #fff;
}

main .bm-account-listing {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .12);
    position: relative;
}

main .bm-account-listing ul {
    margin: 0;
    padding: 0;
}

main .bm-account-listing li {
    display: block;
    padding: 0;
}

main .bm-account-listing li a {
    display: block;
    position: relative;
    text-decoration: none;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

main .bm-account-listing li a span {
    display: block;
    width: 100%;
}

main .bm-account-listing li a span.icon {
    width: 90px;
    height: 60px;
    text-align: center;
    line-height: 74px;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
}

main .bm-account-listing li a span.icon i {
    color: #59daa4;
    font-size: 30px;
    display: inline-block;
    line-height: 40px;
    object-fit: contain;
}

main .bm-account-listing li p.version {
    color: #000;
    padding: 10px;
    text-align: center;
}

main .bm-account-listing li a span.text {
    font-weight: 400;
    position: relative;
    text-align: left;
    color: #000;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid gray;
    position: relative;
    padding-right: 35px;
    padding-left: 90px;

}

main .bm-account-listing li a span.text i {
    display: block;
    position: absolute;
    top: calc(50% - 15px);
    right: 20px;
    font-size: 30px;
    /*color:#59daa4;*/
}

main .nav-tabs .nav-item.show .nav-link, main .nav-tabs .nav-link.active,
main .nav-tabs .nav-link:focus, main .nav-tabs .nav-link:hover {
    color: #000 !important;
    background-color: #1684e4;
    border-color: none !important;
    border: none !important;
    font-size: 14px;
    font-weight: 100;
}

main .nav-tabs .nav-item.show .nav-link, main .nav-tabs .nav-link {
    color: #fff;
    border: none;
}

main .nav-tabs .nav-item.show .nav-link, main .nav-tabs .nav-link a:hover {
    color: #000;
}

main .nav-tabs {
    background-color: #2398ff;
}

main .single-game .nav-tabs .nav-item {
    width: 33.33%;
    text-align: center;
}

main .w-100 {
    width: 100px;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: inherit;
}

main .bm_text_lightpink {
    color: #f07873 !important;
}

main .tournaments .card-body {
    padding: 22px 15px !important;
}

main .tournaments i {
    color: #000;
}

main .bm-card-info-item {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}

main .bm-card-table-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #c1c1c1;
}

main .bm-card-table-item-default {
    font-size: 14px;
    font-weight: 600;
    color: #bfbfbf;
}

main .pin-match {
    position: absolute;
    right: 0;
    padding: 0px 5px;
    margin: 5px;
    color: #000 !important;
    z-index: 1;
    font-size: 25px;
}

main .lottery-join {
    position: absolute;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 2px;
    padding: 0px 5px;
    margin: 10px;
    color: #000 !important;
    z-index: 1;
}

main .tournaments .card-img-top1 {
    transform: scale(1);
    transition: .3s ease-out all;
}

main .tournaments .card-img-top1:hover {
    transform: scale(1.1);
}

main .hide-over {
    overflow: hidden;
}

main .bm-rate-card {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 18px;
    padding-right: 18px;
    transition: .3s ease-out all;
    opacity: 1;
}

main .bm-rate-user, main .bm-rate-place {
    font-size: 12px;
    font-weight: 500;
    color: #c1c1c1;
}

main .bm-rate-wins {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #9b9b9b;
    text-align: right !important;
}

main .bm-rate-position {
    display: inline-block;
    vertical-align: middle;
}

main .br-5 {
    border-radius: 5px;
}

main .bm-rate-position {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    font-weight: 500;
    color: #c1c1c1;
}

main .bm-rate-name {
    font-size: 13px;
    font-weight: 500;
    color: #8a8a8a;
}

main .rate-col .card-table.table thead th, main .rate-col .card-table.table tbody td {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    vertical-align: middle;
}

main .card-table.table td {
    border: 1px solid #dee2e6;
}

main .card-table.table td:first-child {
    border-left: none !important;
}

main .card-table.table td:last-child {
    border-right: none !important;
}

main .game-info .nav-tabs .nav-item {
    width: 50%;
    text-align: center;
}

/* .game-info .tab-pane{
    height:250px;
} */
/* main .card{
    min-height:383px;
} */
main .tab-pane {
    height: calc(100vh - 40px);
    padding-bottom: 90px;
    padding-left: 0;
    padding-right: 0;
}

main .ongoing-match .tab-pane {
    height: calc(100vh - 195px);
    padding-bottom: 165px;
}

main .member-list li {
    padding: 10px;
    border-bottom: 1px solid gray;
}



main .winner, .f-result {
    border-radius: 5px;
    background: #fff;
}

main table th, main table td {
    /*padding:8px 20px !important;*/
    border: 1;
}


.profile {
    border: none;
    background: none;
    padding: 0 !important;
    height: calc(1.5em + .5rem + 2px);
    font-size: 14px;
}

.profile-form textarea {
    border: none;
    background: none;
    padding: 0 !important;
    height: 50px;
    font-size: 14px;
}

main .profile-form .form-control:focus,
main .reset-pwd .form-control:focus {
    background-color: transparent;
    border-color: #000;
    border-bottom: 2px solid #000 !important;
    box-shadow: none;
    outline: 0;
}

main .profile-form label,
main .reset-pwd label {
    margin: 0;
}

main .table-responsive {
    display: table;
}

main .modal, main .modal-backdrop {
    position: absolute !important;
}

main .modal-dialog {
    width: 70%;
    margin: auto;
}

main .modal-content {
    margin-top: 50%;
    border-radius: 15px;
}

main .modal-body {
    padding: 16px 50px 50px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.5em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    color: #f07873 !important;
    border: 1px solid transparent;
    border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    z-index: 3;
    color: #fff !important;
    background-color: #f07873 !important;
    border-color: #f07873;
}

main hr.dash-line {
    border: 1px dashed #000;
}

main .modal-header .close {
    padding: 2px 7px;
    position: absolute;
    right: 20px;
    top: -12px;
    background: #fff;
    border-radius: 50%;

}

main .modal-header {
    border-radius: 15px 15px 0 0;
    display: block;
}

main .error, main .required {
    color: #ff0000;
}

main b, main strong {
    font-weight: 550;
}

main .bg-lightgray {
    background-color: #ece8e838;
}

main .box-shadow {
    box-shadow: 1px 1px 2px 1px #00000026;

}

main .product-card {
    width: 46%;
    margin: 5px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    display: inline-block;
}

main .product-card .card-img-top {
    height: 150px;
}

main .fa-product-hunt.point {
    color: #f4c843;
    border-radius: 50%;
    background-color: #fd7e21;
    font-size: inherit;
}


main .support:hover {
    color: #000;
    text-decoration: none;
}

/* Turn off scrollbar when body element has the loading className */
main .add_money .overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    color: #fff;
    background: rgb(146 141 141 / 80%) url("../images/main.jpg") center no-repeat;
}

main .add_money .loading {
    overflow: hidden;
}

/* Make spinner image visible when body element has the loading className */
main .add_money .loading .overlay {
    display: block;
}

main .add_money .loading .overlay center {
    margin-top: 15%;
}

.active {
    display: block;
}

.hide {
    display: none;
}

.bm-content-listing {
    margin: 10px;
}

.br-5 {
    border-radius: 5px;
}

.hide-over {
    overflow: hidden;
}

.lottery-join {
    position: absolute;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 2px;
    padding: 0px 5px;
    margin: 10px;
    color: #000 !important;
    z-index: 1;
}

.tournaments i {
    color: #000;
}

.tournaments .card-img-top1 {
    transform: scale(1);
    transition: .3s ease-out all;
}

.tournaments .card-body {
    padding: 22px 15px !important;
}

.bm_text_lightpink {
    color: #f07873 !important;
}


.bm-mdl-footer {
    background-color: #2398ff;
    color: #fff;
    position: fixed;
    bottom: 20px;
    width: 500px;
    border-radius: 0 0 15px 15px;
}


.f-18 {
    font-size: 18px !important;
}

.tournaments .card-img-top:hover {
    transform: scale(1.1);
}

.product-card {
    width: 46%;
    margin: 5px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    display: inline-block;
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.b-radius {
    border-radius: 20% !important;
}


.product-card .card-img-top {
    height: 150px;
}

.overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.f-18 {
    font-size: 18px !important;
}

.p-10 {
    padding: 10px;
}

.w-100 {
    width: 100px;
}

.box-shadow {
    box-shadow: 1px 1px 2px 1px #00000026;
}

.bg-lightgray {
    background-color: #ece8e838;
}

.text-black {
    color: #000;
}

.table-responsive {
    display: table;
}


.br-5 {
    border-radius: 5px;
}

.hide-over {
    overflow: hidden;
}

.font-semibold {
    font-weight: 600;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.w-4 {
    width: 1rem;
}

.h-4 {
    height: 1rem;
}

.items-center {
    align-items: center;
}

.text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.w-28 {
    width: 7rem;
}

.grid {
    display: grid;
}

.grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.profile-form select, .profile-form input, .reset-pwd input, .profile-form input {
    border: none;
    background: none;
    padding: 0 !important;
    height: calc(1.5em + .5rem + 2px);
    font-size: 14px;
}

.profile-form .form-control:focus, .reset-pwd .form-control:focus {
    background-color: transparent;
    border-color: #000;
    border-bottom: 2px solid #000 !important;
    box-shadow: none;
    outline: 0;
}

.upcoming-modal-1 {
    left: 6%;
    width: 16%;
    top: 40%;
}

.result-modal {
    left: 100px;
    top: 35%;
    width: 20%;
    border-radius: 25px;
}

/* admin */
.nav-link i {
    font-size: 18px !important;
    vertical-align: text-bottom;
}

:focus {
    outline: none;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

#page-content-wrapper {
    margin-top: 60px;
}

.offcanvas-start {
    top: 52px !important;
    width: 10% !important;

}

.table-bordered {
    border: 2px solid #dee2e6;
}

.dataTables_length {
    padding-bottom: 1%;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    background: #f07873 !important;
    border-color: #f07873 !important;
}

.d-block {
    display: block;
}

.d-none {
    display: none;
}

#sidebar-wrapper .sidebar-heading {
    padding: 5px 13px;
    font-size: 1.2rem;
    background-color: #363636;
    color: #fff;
}

#sidebar-wrapper .list-group {
    width: 15rem;
    margin-top: 60px;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

.nav-item a {
    color: #000000;
}


.nav-item i {
    color: #8e8e8e;
}

.nav-tabs .nav-item.show .navlink, .nav-tabs .navlink.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .navlink {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.navlink {
    border-bottom: none !important;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.img-fluid1 {
    max-width: 20% !important;

}

.text-primary {
    color: #f07873 !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.modal-backdrop.show {
    opacity: 0.05;
}

.navlink {
    font-weight: none !important;
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
}

.navlink:hover i,
a.navlink:hover {
    color: #f07873 !important;
}

.nav-item i:before {
    margin-right: 5px;
}

a.navlink.active, .active i {
    color: #f07873;
}

.page-content {
    margin-left: 0px !important;
}

.main-footer {
    padding: 20px 30px 20px 20px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 40px !important;
    background-color: #f7f7f7;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.bullet, .slash {
    display: inline;
    margin: 0 4px;
}

.small-box {
    padding: 10px;
    color: #fff !important;
    border-radius: 2px;
    position: relative;
    display: block;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.card.card-sm-3, .card.card-sm-4 {
    display: inline-block;
    width: 100%;
}

.card.card-sm-3 .card-icon, .card.card-sm-4 .card-icon {
    width: 80px !important;
    height: 80px !important;
    margin: 0px;
    line-height: 80px;
    font-size: 28px;
    text-align: center;
    float: left;
    margin-right: 15px;
    background-color: #fff !important;
    border-radius: 50% !important;
}

.bg-lightpink {
    background: #f07873;
}

.bg-lightgreen {
    background-color: #59daa4 !important;
}

.bg-lightgreenlight {
    background-color: #bee2d3 !important;
}

.bg-lightblue {
    background-color: #007bff !important;
}

.bg-lightgray {
    background-color: #0c0c0c0a !important;
}

.bg-black {
    background-color: #000000 !important;
}

.text-lightpink {
    color: #f07873;
}

.text-lightgreen {
    color: #59daa4 !important;
}

.text-lightblue {
    color: #007bff !important;
}

.box-shadow {
    box-shadow: 1px 1px 4px 1px #00000024;
}

.card.card-sm-3.bg-lightpink .card-icon i {
    color: #b75753 !important;
}

.card.card-sm-3.bg-lightgreen .card-icon i {
    color: #3f9e76 !important;
}

.card.card-sm-3.bg-lightblue .card-icon i {
    color: #1466bd !important;
}

.card.card-sm-3 .card-icon, .card.card-sm-4 .card-icon i {
    font-size: 35px !important;
}

.card.card-sm-3 .card-header, .card.card-sm-4 .card-header {
    padding-bottom: 0;
    padding-top: 15px;
    background-color: unset;
    border-bottom: 0;

}

.card.card-sm-3 .card-body, .card.card-sm-4 .card-body {
    font-size: 20px;
    padding: 0 !important;

}

.card.card-sm-3 .card-header h4, .card.card-sm-4 .card-header h4 {
    font-size: 12px;
    letter-spacing: 1px;
}

.card.card-lg .card-header h4, .card.card-sm .card-header h4, .card.card-sm-2 .card-header h4, .card.card-sm-3 .card-header h4, .card.card-sm-4 .card-header h4, .card.card-sm-5 .card-header h4 {
    line-height: 1.2;
}

.card-img-top:hover {
    transform: scale(1.1);
}

.card-img-top {
    transform: scale(1);
    transition: .3s ease-out all;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.custom-control {
    position: relative;
    /* display : inline !important; */
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.error {
    color: #ff0000;
}

#wrapper,
body,
html {
    height: 100%;
}

#sidebar-wrapper::-webkit-scrollbar {
    width: 1px;
}

/*Track*/
#sidebar-wrapper::-webkit-scrollbar-track {
    background-color: #efefef;
}

/*Handle*/
#sidebar-wrapper::-webkit-scrollbar-thumb {
    background: #ededed;
}

/*Handle on hover*/
#sidebar-wrapper::-webkit-scrollbar-thumb:hover {
    background: #ededed;
}

#page-content-wrapper {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

#page-content-wrapper .container-fluid {
    -webkit-flex-grow: 1;
    flex-grow: 1;
}

.toast-success {
    background-color: #59daa4;
}

.toast-error {
    background-color: #dc3545;
}

.form_label {
    margin-bottom: 0.5rem !important;
}

.form-control1 {
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dropdown button i {
    vertical-align: middle;
    font-size: 24px;
}

.dropdown-toggle::after {
    vertical-align: middle !important;
}

.hide-on-toggle {
    display: none;
}

.show-on-toggle {
    display: block !important;
}

.game-box .card {
    background-color: #363636;
}

.bm-tot-match {
    position: absolute;
    bottom: 35px;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 2px 5px;
    margin: 5px;
}

.join-lottery {
    position: absolute;
    right: 0;
    background-color: #FFFFFF;
    border-radius: 2px;
    padding: 0px 5px;
    margin: 10px;
    color: #000 !important;
    z-index: 1;
    text-decoration: none;
}

.pin-match {
    position: absolute;
    right: 0;
    padding: 0px 5px;
    margin: 5px;
    z-index: 1;
    text-decoration: none;
    font-size: 25px;
}

.pin-match:hover {
    text-decoration: none;
}

.bm-card-table-item-default {
    font-size: 11px;
    font-weight: 600;
    color: #bfbfbf;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: #f07873a6;
}

.fa-product-hunt.point {
    color: #f4c843;
    border-radius: 50%;
    background-color: #fd7e21;
    font-size: inherit;
}

.slide-img {
    height: 500px !important;
}

/* Turn off scrollbar when body element has the loading class */
.add_money .overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    color: #fff;
    background: rgb(146 141 141 / 80%) url("../images/loader.gif") center no-repeat;
}

.add_money .loading {
    overflow: hidden;
}

/* Make spinner image visible when body element has the loading class */
.add_money .loading .overlay {
    display: block;
}

.add_money .loading .overlay center {
    margin-top: 15%;
}

a[disabled="disabled"], a:disabled {
    cursor: not-allowed !important;
    opacity: .65 !important;
}

button:disabled, input:disabled {
    cursor: not-allowed;
}

.bordered {
    padding: 2px;
    text-align: center;
    min-height: 50px;
    vertical-align: text-bottom;
    border: 1px solid lightgray;
    font-family: 'Montserrat';
}

.position {
    font-size: 12px;
    word-break: break-word;
}

/* owl-carousel */
.owl-dots {
    display: none;
}

.owl-nav button, .owl-nav button span {
    font-size: 30px !important;
    padding: 0px 13px !important;
    color: #fff !important;
    background: #f07873 !important;
}

.owl-nav button span:hover {
    color: #59daa4 !important;

}

/* coin */
.coin {
    vertical-align: sub;
    width: 20px;
}

/* contact */
.cnt-card {
    border-radius: 6px;
    transition: all 0.4s;
}


.about-section .content b, .about-section .content strong, .about-section .content a {
    color: #f07873 !important;
}

/* About us */
.page-header {
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
    height: 25vw;
    position: relative;
}

.page-header .black-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
}


/* main Footer */
#footer a {
    color: #ffffff;
    text-decoration: none !important;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

#footer ul.social li {
    padding: 3px 0;
}

#footer ul.social li a i {
    margin-right: 5px;
    font-size: 25px;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
}

#footer ul.social li:hover a i {
    font-size: 30px;
    margin-top: -10px;
}

#footer ul.social li a, #footer ul.quick-links li a {
    color: #ffffff;
}

#footer ul.social li a:hover, .about-section .content a:hover {
    color: #59daa4 !important;
}

#footer ul.quick-links li {
    padding: 3px 0;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
}

#footer ul.quick-links li:hover {
    padding: 3px 0;
    margin-left: 5px;
    font-weight: 700;
}

#footer ul.quick-links li a i {
    margin-right: 5px;
}

#footer ul.quick-links li:hover a i {
    font-weight: 700;
}

.copyright a:hover {
    color: #fff !important;
}

.copyright a {
    color: #f07873 !important;
}

.copyright a:hover {
    color: #59daa4 !important;
}

.copyright strong, .copyright b {
    color: #59daa4;
}


/* error page */
.error-404 {
    margin-top: 120px;
    margin-bottom: 70px;
    text-align: center;
}

.error-404 .error-code {
    color: #f07873;
    font-size: 96px;
    line-height: 100px;
    font-weight: bold;
}

@media (max-width:1440px) {
    .upcoming-modal-1 {
        width: 20% !important;
        top: 25% !important;
    }

    .modal-dialog {
        max-width: 415px !important;

    }
}

@media (min-width: 1440px) {}

@media (width:1440px) {
    main .bm-mdl-footer {
        width: 437px !important;
    }
}

@media (max-width:1200px) {
    .center-tab .bm_featuretext, .left-tab .bm_featuretext, .right-tab .bm_featuretext {
        width: 68%;
    }

    .center-tab .bm_featurecard.first-card, .left-tab .bm_featurecard.first-card, .right-tab .bm_featurecard.first-card {
        margin-top: 0;
        margin-bottom: 0;
    }

    .right-tab .bm-iconsarea .card, .left-tab .bm-iconsarea .card {
        width: 44%;
    }

    #tournaments .tour-row .card {
        margin: 15px 0;
    }

    .mp-counter {
        position: absolute;
        bottom: 14%;
        right: 38%;
    }

    main .bm-modal {
        margin: 20px auto !important;
    }

    main .col-left {
        padding: 0 !important;
    }

    main .col-right {
        display: none;
        width: 0;
    }

    .tour-row .card {
        margin: 15px 0;
    }
}

@media (min-width:1200px) {
    .tour-row {
        position: relative;
    }

    .rate-col {
        position: absolute;
        top: 1rem;
        right: 0;
    }
}

@media (max-width: 1145px) and (min-width: 992px) {
    .dash-box {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

@media (max-width:1024px) {
    .upcoming-modal-1 {
        left: 34% !important;
        width: 32% !important;
    }

    .result-modal {
        left: 35% !important;
        width: 30% !important;
    }
}

@media (max-width:992px) {
    .center-tab .bm_featuretext, .left-tab .bm_featuretext, .right-tab .bm_featuretext {
        width: 85%;
    }

    .center-tab .left-pane .bm_featuretext {
        text-align: left;
    }

    .center-tab .left-pane .bm_featureicon {
        float: left;
        margin-right: 1rem;
        margin-left: 0;
    }

    #nav-tabContent img {
        display: block;
        margin: 2rem auto 3rem auto;
    }

    .right-tab .bm-iconsarea .card, .left-tab .bm-iconsarea .card {
        width: 44%;
        margin-right: 2.5rem;
    }

    #howtoplay .row.left-img {
        display: block !important;
    }

    #howtoplay .row.right-img {
        flex-direction: column;
    }

    #howtoplay .row.right-img .col_left {
        order: 2;
    }

    #howtoplay .row.right-img .col_right {
        order: 1;
    }

    #howtoplay .row .col {
        margin: 10px 0;
    }

    .page-header {
        height: 100%;
        padding: 150px 0;
        display: block !important;
    }
}

@media(min-width: 992px) {
    .featurette-heading {
        margin-top: 7rem;
    }
}

@media (max-width:768px) {
    #nav-tabContent .bm_featureicon {
        width: 3.5rem;
        height: 3.5rem;
    }

    .center-tab .bm_featuretext, .left-tab .bm_featuretext, .right-tab .bm_featuretext {
        width: 75%;
    }

    #nav-tabContent .bm_featureicon .fa {
        font-size: 1.75rem;
        line-height: 3.5rem;
    }

    .right-tab .bm-iconsarea .card, .left-tab .bm-iconsarea .card {
        width: 100%;
        margin-right: 2.5rem;
    }

    .masthead {
        padding: 100px 0;
        display: block !important;
        height: unset;
        max-height: unset;
    }

    .masthead .row {
        flex-direction: column;
    }

    .masthead .col_left {
        order: 2;
        text-align: center !important;
    }

    .masthead .col_right {
        order: 1;
    }

    .masthead .col_right img {
        margin: 2rem auto 3rem -3rem !important;
    }

    #contact .col_right {
        margin-top: 30px;
    }

    .card-img-top {
        height: auto !important;
    }

    .mp-counter {
        position: absolute;
        bottom: 14%;
        right: 34%;

    }

    #footer h5 {
        padding-left: 0;
        border-left: transparent;
        padding-bottom: 0px;
        margin-bottom: 10px;
    }

    .upcoming-modal-1 {
        left: 29% !important;
        width: 43% !important;
    }

    .result-modal {
        left: 31% !important;
        width: 40% !important;
    }

    .hide-on-mobile {
        display: none;
    }

    #page-content-wrapper {
        display: block;
    }

    .show-on-mobile {
        display: block !important;
    }

    .handsontable table {
        table-layout: unset !important;
    }
}

@media (min-width:768px) {
    .md\:text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

@media(width:768px) {}

@media (min-width: 640px) {
    .featurette-heading {
        font-size: 50px;
    }

    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }

    #wrapper .sidebar-heading {
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
    }

    #sidebar-wrapper {
        position: fixed;
        width: 250px;
        overflow-y: scroll;
        overflow-x: hidden;
        top: 0;
        bottom: 0;
    }

    #page-content-wrapper {
        position: relative;
        margin-left: 250px;
    }

    #wrapper.toggled #sidebar-wrapper {
        position: fixed;
        width: 0;
        overflow-y: hidden;
        /*    top: 0;
            bottom: 0;*/
    }

    #wrapper.toggled #page-content-wrapper {
        /*position: relative;*/
        margin-left: 0px;
    }

    #wrapper.toggled .sidebar-heading {
        position: relative;
    }

    .list-group-flush {
        margin-top: 70px;
    }
}

@media (max-width:580px) {
    main .bm-mdl-footer {
        bottom: 0;
        width: 100%;
        border-radius: 0;
    }

    main .bm-mdl-footer a.btn {
        border-radius: 10px 10px 0 0;
    }


    main .ongoing-match .tab-pane {
        height: calc(100vh - 170px);
    }

    main .bm-modal {
        border-radius: 0;
        max-width: 100%;
        height: calc(100vh - 0px);
        margin: 0px !important;
        width: 100%;
    }

    main .f-18 {
        font-size: 16px !important;
    }

    main .f-20 {
        font-size: 18px !important;
    }

    main .f-24 {
        font-size: 22px !important;
    }
}

@media(max-width:576px) {
    .team-header {
        display: none;
    }

    .team_name {
        display: inline !important;
    }

    .position {
        padding: 0 !important;
    }

    .player_name {
        padding: 14px;
        word-break: break-word;
    }
}

@media(min-width: 576px) {
    .team-header {
        display: block;
    }

    .team_name {
        display: none;
    }

    .player_name {
        padding-top: 8px;
        word-break: break-word;
    }
}

@media(max-width:460px) {
    main .ongoing-match .tab-pane {
        height: calc(100vh - 140px);
    }

    main .ongoing-match .tab-pane {
        padding-bottom: 150px;
    }
}

@media (max-width:425px) {
    .upcoming-modal-1 {
        left: 12% !important;
        width: 76% !important;
    }

    .result-modal {
        left: 15% !important;
        width: 71% !important;
    }

    .mp-counter {
        position: absolute;
        bottom: 14%;
        right: 20%;
    }
}

@media (max-width:375px) {
    .bm-iconsarea {
        text-align: center !important;
    }
}

@media(max-width:360px) {
    .center-tab .bm_featuretext, .left-tab .bm_featuretext, .right-tab .bm_featuretext {
        width: 70%;
    }

    main .ongoing-match .tab-pane {
        padding-bottom: 120px;
    }

    main .table-responsive {
        display: block;
    }

    main .support .fa-2x {
        font-size: 1em !important;
    }
}