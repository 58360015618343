.side-navigation-panel {
  margin-top: 60px !important;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option {
  padding: 0.4rem 2.8rem !important;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option:hover {
  background-color: white !important;
  border-color: white !important;
  color: #f07873 !important;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option {
  border-left-width: 0px !important;
  padding: 0.55rem 1.5rem !important;

}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
  margin-left: 0.2rem !important;

}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option-selected {
  background-color: white !important;
  border-color: white !important;

}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-inner .side-navigation-panel-select-inner-wrap .side-navigation-panel-select-inner-option:hover {
  background-color: white !important;
  border-color: white !important;
  color: #f07873 !important;
}

.text-skyblue {
  color: #9ecaf8 !important;
}

.custom-control-inline {
  display: inline-flex;

}

.disabled {
  cursor: not-allowed !important;
  opacity: .65 !important;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected {
  background-color: white !important;

}

.navicon {
  color: gray !important;
  margin-right: 5px;
  font-size: 18px;
}

.navicon:active {
  color: #f07873 !important;
}

.visible {
  display: flex;
}

.hidden {
  display: none
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.iconsHover:hover {
  color: #f07873 !important;
}

.handsontable {
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.3em;
  font-size: 13px;
}

.handsontable table {
  position: relative;
  border-spacing: 0;
  margin: 0;
  border-width: 0;
  table-layout: fixed;
  width: 0;
}

.order-main-tbl input {
  width: 99%;
  height: 95%;
  margin-top: 1px;
}

.dataTables_filter {
  margin-bottom: 2% !important;
}

.body {

  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #363636;
  width: 100%;
  height: 100vh;
}

.select2-container .select2-selection--single {
  height: 60px !important;
}

.select2-results__option {
  color: black !important;
}

#user {
  text-decoration: none;
}

#member {
  text-decoration: none;
}

#user:hover {
  text-decoration: underline;
  color: #f07873;
}

#member:hover {
  text-decoration: underline;
  color: #f07873;
}

body {
  font-size: .875rem;
}

.nav-link i {
  font-size: 18px !important;
  vertical-align: text-bottom;
}

/* Sidebar */
:focus {
  outline: none;
}

#page-content-wrapper {
  margin-top: 60px;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 5px 13px;
  font-size: 1.2rem;
  background-color: #363636;
  color: #fff;
}

#sidebar-wrapper .list-group {
  width: 15rem;
  margin-top: 60px;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

.nav-item a {
  color: #000000;
}

.nav-item i {
  color: #8e8e8e;
}

.nav-link:hover i,
a.nav-link:hover {
  color: #f07873 !important;
}

.nav-item i:before {
  margin-right: 5px;
}

a.nav-link.active, .active i {
  color: #f07873;
}

#menu-toggle .btn.focus, .btn:focus {
  outline: 0;
  box-shadow: unset !important;
}

.main-footer {
  padding: 20px 30px 20px 20px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 40px;
  background-color: #f7f7f7;
  width: 100%;
}

.bullet, .slash {
  display: inline;
  margin: 0 4px;
}

a.btn-sm.btn-outline-secondary {
  color: #f07873;
  border-color: #f07873;
}

a.btn-sm.btn-outline-secondary:hover {
  color: #fff;
  background-color: #f07873;
  border-color: #f07873;
}

.small-box {
  padding: 10px;
  color: #fff !important;
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.card.card-sm-3, .card.card-sm-4 {
  display: inline-block;
  width: 100%;
}

.card.card-sm-3 .card-icon, .card.card-sm-4 .card-icon {
  width: 80px !important;
  height: 80px !important;
  margin: 0px;
  line-height: 80px;
  font-size: 28px;
  text-align: center;
  float: left;
  margin-right: 15px;
  background-color: #fff !important;
  border-radius: 50% !important;
}

.bg-lightpink {
  background: #f07873;
}

.bg-lightgreen {
  background-color: #59daa4 !important;
}

.bg-lightblue {
  background-color: #007bff !important;
}

.card.card-sm-3.bg-lightpink .card-icon i {
  color: #b75753 !important;
}

.card.card-sm-3.bg-lightgreen .card-icon i {
  color: #3f9e76 !important;
}

.card.card-sm-3.bg-lightblue .card-icon i {
  color: #1466bd !important;
}

.card.card-sm-3 .card-icon, .card.card-sm-4 .card-icon i {
  font-size: 35px !important;
}

.card.card-sm-3 .card-header, .card.card-sm-4 .card-header {
  padding-bottom: 0;
  padding-top: 15px;
  background-color: unset;
  border-bottom: 0;

}

.card.card-sm-3 .card-body, .card.card-sm-4 .card-body {
  font-size: 20px;
  padding: 0 !important;
}

.card.card-sm-3 .card-header h4, .card.card-sm-4 .card-header h4 {
  font-size: 12px;
  letter-spacing: 1px;
}

.card.card-lg .card-header h4, .card.card-sm .card-header h4, .card.card-sm-2 .card-header h4, .card.card-sm-3 .card-header h4, .card.card-sm-4 .card-header h4, .card.card-sm-5 .card-header h4 {
  line-height: 1.2;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.custom-control {
  position: relative;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.error {
  color: #ff0000;
}

#wrapper,
body,
html {
  height: 100%;
}

#sidebar-wrapper::-webkit-scrollbar {
  width: 1px;
}

#sidebar-wrapper::-webkit-scrollbar-track {
  background-color: #efefef;
}

#sidebar-wrapper::-webkit-scrollbar-thumb {
  background: #ededed;
}

#sidebar-wrapper::-webkit-scrollbar-thumb:hover {
  background: #ededed;
}

#page-content-wrapper {
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

#page-content-wrapper .container-fluid {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.navbar .dropdown-menu {
  margin-top: 0.5rem !important;
}

.dropdown button i {
  vertical-align: middle;
  font-size: 24px;
}

.dropdown-toggle::after {
  vertical-align: middle !important;
}

.hide-on-toggle {
  display: none;
}

.show-on-toggle {
  display: block !important;
}

.fa-product-hunt.point {
  color: #f4c843;
  border-radius: 50%;
  background-color: #fd7e21;
  font-size: inherit;
}

.multiselect-container {
  width: 100%;
}

.badge-success {
  color: #fff !important;
  background-color: #28a745 !important;
}

table.dataTable tr th.select-checkbox.selected::after {
  content: "✔";
  margin-top: -11px;
  margin-left: -4px;
  text-align: center;
  text-shadow: rgb(176, 190, 217) 1px 1px, rgb(176, 190, 217) -1px -1px, rgb(176, 190, 217) 1px -1px, rgb(176, 190, 217) -1px 1px;
}

.text-skyblue {
  color: #9ecaf8 !important;
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

  #page-content-wrapper {
    display: block;
  }

  .show-on-mobile {
    display: block !important;
  }

  .handsontable table {
    table-layout: unset !important;
  }
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }

  #wrapper .sidebar-heading {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
  }

  #sidebar-wrapper {
    position: fixed;
    width: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
  }

  #page-content-wrapper {
    position: relative;
    margin-left: 250px;
  }

  #wrapper.toggled #sidebar-wrapper {
    position: fixed;
    width: 0;
    overflow-y: hidden;
  }

  #wrapper.toggled #page-content-wrapper {
    margin-left: 0px;
  }

  #wrapper.toggled .sidebar-heading {
    position: relative;
  }

  .list-group-flush {
    margin-top: 70px;
  }
}

@media (max-width: 1145px) and (min-width: 992px) {
  .dash-box {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

@media (max-width: 575px) {
  .team-header {
    display: none;
  }

  .team_name {
    display: inline !important;
  }

  .position {
    padding: 0 !important;
  }
}

@media (min-width: 576px) {
  .team-header {
    display: block;
  }

  .team_name {
    display: none;
  }
}

.bordered {
  padding: 2px;
  text-align: center;
  min-height: 50px;
  vertical-align: text-bottom;
  border: 1px solid lightgray;
  font-family: 'Montserrat';
}

.position {
  font-size: 12px;
  word-break: break-word;
}

@media (max-width: 576px) {
  .player_name {
    padding: 14px;
    word-break: break-word;
  }
}

@media (min-width: 576px) {
  .player_name {
    padding-top: 8px;
    word-break: break-word;
  }
}